import { Button, ButtonTheme, NCInput } from "@wolfiesports/srm-component";
import moment from "moment";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import {
  cancelSubscription,
  getBills,
  getMyAccount,
} from "../../../services/subscription";
import "../UserSettings.scss";

type SubscriptionBills = {
  currency: string;
  date: number;
  email: string;
  endDate: number;
  invoice: null;
  name: string;
  paymentMethod: string;
  price: string;
  startDate: number;
  state: string;
  subscriptionId: string;
  target: string;
  userId: string;
  _id: string;
};

type SubscriptionAccount = {
  active: boolean;
  coin: number;
  email: string;
  endDate: number;
  nextPayment: number;
  paymentMethod: string;
  paypalId: string;
  status: string;
  stripeId: string;
  subscriptionId: string;
  trial: boolean;
  userId: string;
  _id: string;
};

export const PremiumHistorySettings = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [voucherCode, setVoucherCode] = useState("");
  const [bills, setBills] = useState<Array<SubscriptionBills>>();
  const [currentSubscription, setCurrentSubscripiton] =
    useState<SubscriptionAccount>();
  const handleVoucherCodeChange = (value: string) => {
    setVoucherCode(value);
  };

  const handleSendVoucher = () => {
    console.log(voucherCode);
  };

  useEffect(() => {
    const fetchBills = async () => {
      const res = await getBills();
      setBills(res.data);
      console.log(res.data);
    };

    fetchBills();
  }, []);

  useEffect(() => {
    const fetchMyAccounts = async () => {
      const res = await getMyAccount();
      setCurrentSubscripiton(res.data);
      console.log(res.data);
    };

    fetchMyAccounts();
  }, []);

  const handleCancelSubscription = async () => {
    cancelSubscription(currentSubscription?._id ?? "");
  };

  const handleRedirectToPremium = () => {
    navigate("/premium");
  };
  return (
    <div className="account-settings-content">
      <div className="title">
        <h4>
          <FormattedMessage id={"menu.label.premium"} />
        </h4>
      </div>
      <div className="premium-settings-page-container">
        <div className="add-voucher-form-container">
          <h5>
            <FormattedMessage id={"webapp.premium.add-a-voucher"} />
          </h5>
          <div className="form-container">
            <NCInput
              name={intl.formatMessage({ id: "webapp.premium.add-a-voucher" })}
              placeHolder={intl.formatMessage({ id: "webapp.premium.voucher" })}
              value={voucherCode}
              onChange={handleVoucherCodeChange}
            />
            <Button
              setClick={handleSendVoucher}
              label={
                <FormattedMessage id={"webapp.premium.send-voucher-button"} />
              }
            />
          </div>
        </div>
        <div className="history-table-container">
          <h5>
            <FormattedMessage id={"webapp.premium.payment-history"} />
          </h5>
          {bills && bills.length ? (
            <table className="premium-history-table">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id={"webapp.premium.table-column-date"} />
                  </th>
                  <th>
                    <FormattedMessage
                      id={"webapp.premium.table-column-start-date"}
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id={"webapp.premium.table-column-end-date"}
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id={"webapp.premium.table-column-description"}
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id={"webapp.premium.table-column-payment-method"}
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id={"webapp.premium.table-column-payment-status"}
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id={"webapp.premium.table-column-total"}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {bills.map((entry, index) => (
                  <tr key={index}>
                    <td>{moment(entry.date).format("DD/MM/YYYY")}</td>
                    <td>{moment(entry.startDate).format("DD/MM/YYYY")}</td>
                    <td>{moment(entry.endDate).format("DD/MM/YYYY")}</td>
                    <td>{entry.name}</td>
                    <td>{entry.paymentMethod}</td>
                    <td>{entry.state}</td>
                    <td>
                      {entry.price}
                      {entry.currency}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}
        </div>
        {currentSubscription && (
          <div className="current-subscription">
            Your current subscription {currentSubscription.status} is ending on{" "}
            {moment(currentSubscription.endDate).format("DD/MM/YYYY")}
            <br />
            {currentSubscription.active ? (
              <>
                You can cancel it anytime by clicking this button :{" "}
                <Button
                  setClick={handleCancelSubscription}
                  label={"Cancel my subscription"}
                  theme={ButtonTheme.RED}
                />
              </>
            ) : (
              <>
                Your subscription is cancelled, you can buy another one here :{" "}
                <Button
                  setClick={handleRedirectToPremium}
                  theme={ButtonTheme.PREMIUM}
                  label={"Go premium again"}
                />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
