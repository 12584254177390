import { PaymentIntentType } from "../models/SubscriptionModel";
import { axiosAuthenticated } from "./Auth";

export const mpesaPay = async (planId: string, phoneNumber: string) => {
  try {
    const res = await axiosAuthenticated.post(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/apply/mpesa/plans/${planId}`,
      { phoneNumber }
    );
    return res;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const postMpesaIntent = async (type: PaymentIntentType, data: any) => {
  try {
    const res = await axiosAuthenticated.post(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/paymentintent/mpesa/`,
      { type, data }
    );
    return res;
  } catch (e: any) {
    throw new Error(e.message);
  }
};
