import {
  NCRadioGroup,
  NCSelect,
  NcRadioGroupFields,
} from "@wolfiesports/srm-component";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { UserModel } from "../../../models/User";
import { putUserCurrency, putUserLanguage } from "../../../services/no-games";
import "../UserSettings.scss";

const currencies: NcRadioGroupFields[] = [
  { key: "EUR (€)", value: "eur" },
  { key: "KES", value: "kes" },
  { key: "USD ($)", value: "usd" },
];

const languageOptions = [
  { label: "French", value: "fr" },
  { label: "English", value: "en" },
  { label: "Arabic", value: "ar" },
  { label: "Spanish", value: "es" },
  { label: "Portuguese", value: "pt" },
  { label: "German", value: "de" },
];

type CurrencyContentProps = {
  user: UserModel;
};
export const CurrencyContent = ({ user }: CurrencyContentProps) => {
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [language, setLanguage] = useState(
    localStorage.getItem("i18nextLng") ?? "en"
  );

  const handleCurrencyChange = async (currency: string) => {
    setSelectedCurrency(currency);

    await putUserCurrency(user.identity.id, currency);
  };

  const handleLanguageChange = async (language: string | undefined) => {
    if (language) {
      setLanguage(language);
      handleChangeLang(language);
      await putUserLanguage(user.identity.id, language);
    }
  };

  const handleChangeLang = async (language: string) => {
    localStorage.setItem("i18nextLng", language);
    window.dispatchEvent(new Event("languageChange"));
  };

  //   useEffect(() => {
  //     const handleLanguageChange = () => {
  //       const newLang = localStorage.getItem("i18nextLng") ?? "en";
  //       setLanguage(newLang);
  //     };

  //     window.addEventListener("languageChange", handleLanguageChange);

  //     return () => {
  //       window.removeEventListener("languageChange", handleLanguageChange);
  //     };
  //   }, []);

  return (
    <div className="account-settings-content">
      <div className="title">
        <h4>
          <FormattedMessage id={"webapp.settings.currency"} />
        </h4>
        <h5>
          <FormattedMessage
            id={"webapp.settings.currency.personnal-information"}
          />
        </h5>
        <span>
          <FormattedMessage
            id={"webapp.settings.currency.personal-information-sentence"}
          />
        </span>
      </div>
      <div className="content">
        <div className="currency-container">
          <div className={"item"}>
            <NCRadioGroup
              value={selectedCurrency}
              actionHook={handleCurrencyChange}
              fields={currencies}
            />
          </div>
        </div>
        <div className="input-styles">
          <h6>
            <FormattedMessage id={"webapp.settings.currency.language"} />
          </h6>
          <NCSelect
            selectFields={languageOptions}
            actionHook={handleLanguageChange}
            fieldValue="value"
            fieldName="label"
            selectedField={language}
          />
        </div>
      </div>
    </div>
  );
};
