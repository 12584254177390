import { Icon, IconType } from "@wolfiesports/srm-component";
import { useState } from "react";
import "./ComparePlansSection.scss";

const bgGradient = require("../../../assets/Premium/premium-gradient.png");
const checkMarkGold = require("../../../assets/Premium/checkmark-gold.png");
const checkMarkGrey = require("../../../assets/Premium/checkmark-grey.png");

export const PremiumComparePlansSection = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="compare-plan-layout">
      <div className="compare-plan-button" onClick={handleIsOpen}>
        <h5>Compare plans</h5>
        <div className={`icon-container${isOpen ? " open" : ""}`}>
          <Icon icon={IconType.UpArrow} width={20} height={20} color="white" />
        </div>
      </div>

      <div className={`compare-plans-container${isOpen ? " open" : ""}`}>
        <div className="column-section">
          <div className="title-section">
            <div
              className="premium-gradient-img"
              style={{ backgroundImage: `url(${bgGradient})` }}
            />
            <h5>Benefits</h5>
          </div>

          <div className="table-line">
            <p>Compete in our cash-prize tournaments in 25+ games</p>
          </div>

          <div className="table-line">
            <p>Track your in-game statistics</p>
          </div>
          <div className="table-line">
            <p>Stand out from the crowd with premium badge</p>
          </div>
          <div className="table-line">
            <p>Improve your performance with specialized training programs</p>
          </div>
          <div className="table-line">
            <p>Withdraw your cash prize at a lower limit</p>
          </div>
          <div className="table-line">
            <p>Get your cash faster</p>
          </div>
          <div className="table-line">
            <p>
              Personalize your Gamer profile with exclusive items coming soon
            </p>
          </div>
          <div className="table-line">
            <p>Unlock Premium avatars, banners and frame coming soon</p>
          </div>
        </div>

        <div className="column-section">
          <div className="title-section">
            <h5>Premium</h5>
          </div>

          <div className="table-line">
            <div
              className="checkmark-img"
              style={{ backgroundImage: `url(${checkMarkGold})` }}
            />
          </div>

          <div className="table-line">
            <div
              className="checkmark-img"
              style={{ backgroundImage: `url(${checkMarkGold})` }}
            />
          </div>
          <div className="table-line">
            <div
              className="checkmark-img"
              style={{ backgroundImage: `url(${checkMarkGold})` }}
            />
          </div>
          <div className="table-line">
            <p>Unlimited</p>
          </div>
          <div className="table-line">
            <p>€20</p>
          </div>
          <div className="table-line">
            <p>3 working days</p>
          </div>
          <div className="table-line">
            <p>Unlimited</p>
          </div>
          <div className="table-line">
            <div
              className="checkmark-img"
              style={{ backgroundImage: `url(${checkMarkGold})` }}
            />
          </div>
        </div>

        <div className="column-section">
          <div className="title-section">
            <h5>Premium +</h5>
          </div>

          <div className="table-line">
            <div
              className="checkmark-img"
              style={{ backgroundImage: `url(${checkMarkGold})` }}
            />
          </div>

          <div className="table-line">
            <div
              className="checkmark-img"
              style={{ backgroundImage: `url(${checkMarkGold})` }}
            />
          </div>
          <div className="table-line">
            <div
              className="checkmark-img"
              style={{ backgroundImage: `url(${checkMarkGold})` }}
            />
          </div>
          <div className="table-line">
            <p>Unlimited</p>
          </div>
          <div className="table-line">
            <p>€20</p>
          </div>
          <div className="table-line">
            <p>3 working days</p>
          </div>
          <div className="table-line">
            <p>Unlimited</p>
          </div>
          <div className="table-line">
            <div
              className="checkmark-img"
              style={{ backgroundImage: `url(${checkMarkGold})` }}
            />
          </div>
        </div>

        <div className="column-section">
          <div className="title-section">
            <h5>Premium Melty</h5>
          </div>

          <div className="table-line">
            <div
              className="checkmark-img"
              style={{ backgroundImage: `url(${checkMarkGold})` }}
            />
          </div>

          <div className="table-line">
            <div
              className="checkmark-img"
              style={{ backgroundImage: `url(${checkMarkGold})` }}
            />
          </div>
          <div className="table-line">
            <div
              className="checkmark-img"
              style={{ backgroundImage: `url(${checkMarkGold})` }}
            />
          </div>
          <div className="table-line">
            <p>Unlimited</p>
          </div>
          <div className="table-line">
            <p>€20</p>
          </div>
          <div className="table-line">
            <p>3 working days</p>
          </div>
          <div className="table-line">
            <p>Unlimited</p>
          </div>
          <div className="table-line">
            <div
              className="checkmark-img"
              style={{ backgroundImage: `url(${checkMarkGold})` }}
            />
          </div>
        </div>

        <div className="column-section">
          <div className="title-section">
            <h5>Free</h5>
          </div>

          <div className="table-line">
            <div
              className="checkmark-img"
              style={{ backgroundImage: `url(${checkMarkGrey})` }}
            />
          </div>

          <div className="table-line">
            <div
              className="checkmark-img"
              style={{ backgroundImage: `url(${checkMarkGrey})` }}
            />
          </div>
          <div className="table-line">
            <div
              className="checkmark-img"
              style={{ backgroundImage: `url(${checkMarkGrey})` }}
            />
          </div>
          <div className="table-line">
            <p>Unlimited</p>
          </div>
          <div className="table-line">
            <p>€20</p>
          </div>
          <div className="table-line">
            <p>3 working days</p>
          </div>
          <div className="table-line">
            <p>Unlimited</p>
          </div>
          <div className="table-line">
            <div
              className="checkmark-img"
              style={{ backgroundImage: `url(${checkMarkGold})` }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
