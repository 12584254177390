import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Button, ButtonTheme, NCDialog } from "@wolfiesports/srm-component";
import { useEffect, useState } from "react";
import { SubscriptionPlan } from "../../models/SubscriptionModel";
import { PremiumModalMpesaMethod } from "./Steps/Mpesa";
import {
  PaymentMethod,
  PremiumModalPaymentMethod,
} from "./Steps/PaymentMethod";
import { PremiumModalPaypalMethod } from "./Steps/Paypal";
import { SelectionPlan } from "./Steps/SelectionPlan";
import { PremiumModalStripeMethod } from "./Steps/Stripe";
import "./styles.scss";

const stripePromise = loadStripe(
  "pk_test_51NveXSA3vxATVpovyRhesbl4Sn72cePEw8hyuJlOTY6VWRJLYUgNAu6mWSbHUi6ncXSnZFuwEdwp0UVcrjGJBhzP00MpyoT7xa"
);

type PremiumModalProps = {
  handleOpenModal: boolean;
  handleCloseModal: () => void;
  selectedProductId?: string;
};

export const PremiumModal = ({
  handleOpenModal,
  handleCloseModal,
  selectedProductId,
}: PremiumModalProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const steps = [0, 1, 2, 3];

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    PaymentMethod | undefined
  >();

  const handleSelectPaymentMethod = (method: PaymentMethod) => {
    setSelectedPaymentMethod(method);
    setCurrentStep(2);
  };

  const handleSelectPlan = (selectedPlan: SubscriptionPlan) => {
    setSelectedPlan(selectedPlan);
    setCurrentStep(1);
  };

  const showSuccess = () => {
    setCurrentStep(3);
  };

  const showFailure = (reason: string) => {
    setCurrentStep(3);
    setErrorMessage(reason);
  };

  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  useEffect(() => {
    setCurrentStep(0);
    setSelectedPlan(undefined);
    setErrorMessage(null);
    setSelectedPaymentMethod(undefined);
  }, [handleOpenModal]);

  return (
    <NCDialog show={handleOpenModal} setShow={handleCloseModal}>
      <div className="premium-modal-container">
        <div className="stepper-container">
          {steps.map((step) => (
            <div
              key={step}
              className={`step ${currentStep === step ? "active" : ""} ${errorMessage && currentStep === step ? "error" : ""}`}
            />
          ))}
        </div>

        {currentStep === 0 && (
          <SelectionPlan
            handleSelectPlan={handleSelectPlan}
            selectedProduct={selectedProductId}
          />
        )}

        {currentStep === 1 && selectedPlan && (
          <PremiumModalPaymentMethod
            setSelectedPaymentMethod={(method: PaymentMethod) =>
              handleSelectPaymentMethod(method)
            }
            handlePrevStep={handlePrevStep}
            selectedPlan={selectedPlan}
          />
        )}

        {currentStep === 2 &&
          selectedPaymentMethod === "paypal" &&
          selectedPlan && (
            <PremiumModalPaypalMethod
              selectedPlan={selectedPlan}
              paymentSuccess={showSuccess}
              paymentFailed={showFailure}
              handlePrevStep={handlePrevStep}
            />
          )}

        {currentStep === 2 &&
          selectedPaymentMethod === "stripe" &&
          selectedPlan && (
            <Elements stripe={stripePromise}>
              <PremiumModalStripeMethod
                selectedPlan={selectedPlan}
                paymentSuccess={showSuccess}
                paymentFailed={showFailure}
                handlePrevStep={handlePrevStep}
              />
            </Elements>
          )}

        {currentStep === 2 &&
          selectedPaymentMethod === "mpesa" &&
          selectedPlan && (
            <PremiumModalMpesaMethod selectedPlan={selectedPlan} />
          )}

        {currentStep === 3 && (
          <div className="success-error-step-container">
            {errorMessage ? (
              <>
                <h5>Payment failure</h5>
                <p>{errorMessage}</p>
              </>
            ) : (
              <>
                <h5>Payment success !</h5>
                <p>Congratulations!</p>
                <div>
                  <p>You purchased your {selectedPlan?.name} pack.</p>
                  <p>
                    You will receive a confirmation email and you can find your
                    invoice any time in your billing details
                  </p>
                </div>

                <Button
                  theme={ButtonTheme.PREMIUM}
                  label={"Download receipt"}
                />
              </>
            )}
          </div>
        )}
      </div>
    </NCDialog>
  );
};
