import { PremiumProduct } from "../models/SubscriptionModel";
import { axiosAuthenticated } from "./Auth";

export const deleteSubscriptionAddress = async (id: string) => {
  try {
    const res = await axiosAuthenticated.delete(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/address/${id}`
    );
    return res.data;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const postAddress = async (address: any) => {
  const payload = {
    name: address.firstName + " " + address.lastName,
    address: {
      city: address.city,
      country: address.selectedCountry.iso2,
      line1: address.billingAddress,
      line2: address.secondBillingAddress || "",
      postal_code: address.zipCode,
      state: "",
    },
  };

  try {
    const res = await axiosAuthenticated.post(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/adresses`,
      payload
    );
    return res;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const postTrialSubscription = async (productId: string) => {
  try {
    const res = await axiosAuthenticated.post(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/apply/trial/${productId}`
    );
    return res.data;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const cancelSubscription = async (id: string) => {
  try {
    const res = await axiosAuthenticated.post(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/apply/cancel`,
      { id }
    );
    return res.data;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const getAllProducts = async (): Promise<Array<PremiumProduct>> => {
  try {
    const res = await axiosAuthenticated.get(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/products`
    );
    return res.data.data;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const getProductById = async (productId: string) => {
  try {
    const res = await axiosAuthenticated.get(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/products/${productId}`
    );
    return res.data;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const getProductByName = async (name: string) => {
  try {
    const res = await axiosAuthenticated.get(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/products?name=${name}`
    );
    return res.data[0];
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const getPlan = async (productId: string, currency?: string) => {
  try {
    const res = await axiosAuthenticated.get(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/products/${productId}/plans${currency ? "?currency=" + currency : ""}`
    );
    return res.data;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const getAllPlans = async (): Promise<any> => {
  try {
    const res = await axiosAuthenticated.get(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/plans`
    );
    return res.data;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const getBills = async () => {
  try {
    const res = await axiosAuthenticated.get(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/bills`
    );
    return res.data;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const getMyAccount = async () => {
  try {
    const res = await axiosAuthenticated.get(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/accounts/me`
    );
    return res.data;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const getPlanById = async (planId: string) => {
  try {
    const res = await axiosAuthenticated.get(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/plans/${planId}`
    );
    return res.data;
  } catch (e: any) {
    throw new Error(e.message);
  }
};
