import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { AuthPage } from "../pages/Auth/Auth";
import { ForgotPasswordPage } from "../pages/Auth/Forgot/Forgot";
import { ChallengePage } from "../pages/Challenge/Challenge";
import { ContactPage } from "../pages/Contact/Contact";
import { GameAccountPage } from "../pages/GameAccount/GameAccount";
import { PrivacyPage } from "../pages/Legals/Privacy";
import { TermOfSalesPage } from "../pages/Legals/TermOfSales";
import { TermOfUsePage } from "../pages/Legals/TermOfUse";
import { OnBoardingPage } from "../pages/OnBoarding/OnBoarding";
import { OrganisationPage } from "../pages/Organisation/Organisation";
import { PremiumPage } from "../pages/Premium/Premium";
import { ProfilePage } from "../pages/Profile/Profile";
import { UserSettingsPage } from "../pages/Settings/UserSettings";
import { TeamPage } from "../pages/Team/Team";
import { TournamentPage } from "../pages/Tournament/Tournament";
import { WalletPage } from "../pages/Wallet/Wallet";
import { useStoreState } from "../store";

export const BaseRouter = () => {
  const user = useStoreState((s) => s.login.user);

  const PrivateRoute = () => {
    if (!user) {
      return <Navigate to={"/login"} replace />;
    }

    return <Outlet />;
  };

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/tournament/home" />} />
      <Route path="/tournament/*" element={<TournamentPage />} />
      <Route path="/login" element={<AuthPage />} />
      <Route path="/forgot" element={<ForgotPasswordPage />} />
      <Route path="/register" element={<AuthPage />} />
      <Route path="/terms-of-use" element={<TermOfUsePage />} />
      <Route path="/terms-of-sales" element={<TermOfSalesPage />} />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route element={<PrivateRoute />}>
        <Route path="/profile/:userId" element={<ProfilePage />} />
        <Route path="/onboarding" element={<OnBoardingPage />} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/team/profile/:teamid" element={<TeamPage />} />
        <Route path="/game-accounts" element={<GameAccountPage />} />
        <Route path="/wallet" element={<WalletPage />} />
        <Route path="/settings" element={<UserSettingsPage />} />
        <Route path="/premium" element={<PremiumPage />} />
        <Route
          path="/organisation/:organisationDomain?"
          element={<OrganisationPage />}
        />
        <Route path="/challenges/*" element={<ChallengePage />} />
      </Route>
      <Route path="*" element={<Navigate to="/tournament/home" />} />
    </Routes>
  );
};
