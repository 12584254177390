import { Button, ButtonTheme, IconType } from "@wolfiesports/srm-component";
import { useEffect, useState } from "react";
import { PremiumModal } from "../../components/PremiumModal/PremiumModal";
import { PremiumProduct } from "../../models/SubscriptionModel";
import { getAllProducts } from "../../services/subscription";
import { PremiumComparePlansSection } from "./ComparePlansSection/ComparePlansSection";
import "./Premium.scss";

const bgImage = require("../../assets/Premium/premium-bg.png");
const bgScrached = require("../../assets/Premium/scratch-texture.png");
const premiumImage = require("../../assets/Premium/premium.png");
const tropheyImage = require("../../assets/Premium/premium-trophey.png");
const crownImage = require("../../assets/Premium/crown-melty.png");

const premiumPlansItems = [
  {
    topImage: premiumImage,
    price: "4.99 €",
    items: [
      "Diam ut venenatis tellus in metus.",
      "Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      "Lorem ipsum dolor",
      "Lorem ipsum dolor sit amet, consectetur",
    ],
  },
  {
    topImage: tropheyImage,
    price: "6.99 €",
    items: [
      "Diam ut venenatis tellus in metus.",
      "Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      "Lorem ipsum dolor",
      "Lorem ipsum dolor sit amet, consectetur",
    ],
  },
  {
    topImage: crownImage,
    price: "7.99 €",
    items: [
      "Diam ut venenatis tellus in metus.",
      "Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      "Lorem ipsum dolor",
      "Lorem ipsum dolor sit amet, consectetur",
    ],
  },
];

export const PremiumPage = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [products, setProducts] = useState<PremiumProduct[]>();
  const [selectedProductId, setSelectedProductId] = useState<string>();

  const fetchProducts = async () => {
    const res = await getAllProducts();
    setProducts(res);
  };
  useEffect(() => {
    fetchProducts();
  }, []);

  const handleOpenModal = (product: PremiumProduct) => () => {
    setIsOpenModal(!isOpenModal);
    if (product) {
      setSelectedProductId(product._id);
    }
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  return (
    <>
      <div className="premium-page-container">
        <div
          style={{ backgroundImage: `url(${bgImage})` }}
          className="bg-image-container"
        />
        <div className="content-container">
          <div className="title-container">
            <h3>Unlock the power of an all-in-one platform</h3>
            <h4>Build now your success faster than ever</h4>
          </div>
          <div className="premium-presentation-container">
            {products &&
              premiumPlansItems.map((planItem, index) => {
                return (
                  <div
                    className="premium-item"
                    style={{ backgroundImage: `url(${bgScrached})` }}
                    key={index}
                  >
                    <div
                      className="top-image-container"
                      style={{ backgroundImage: `url(${planItem.topImage})` }}
                    />
                    <div className="premium-item-price">
                      <h5>{planItem.price}</h5>
                      <p>/month</p>
                    </div>
                    <div className="premium-item-list">
                      {planItem.items.map((item, index) => {
                        return (
                          <div className="premium-list-items" key={index}>
                            <div className="dot" />
                            <p>{item}</p>
                          </div>
                        );
                      })}
                    </div>
                    <Button
                      theme={ButtonTheme.PREMIUM}
                      icon={{ type: IconType.Premium, width: 20, height: 20 }}
                      setClick={handleOpenModal(products[index])}
                    >
                      Subscribe
                    </Button>
                  </div>
                );
              })}
          </div>
          <PremiumComparePlansSection />
        </div>
      </div>
      <PremiumModal
        handleOpenModal={isOpenModal}
        handleCloseModal={handleCloseModal}
        selectedProductId={selectedProductId}
      />
    </>
  );
};
