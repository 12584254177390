import { useEffect, useState } from "react";
import { SubscriptionPlan } from "../../../models/SubscriptionModel";
import { PlanItem } from "../../../pages/Premium/PlanItem/PlanItem";
import { getAllPlans } from "../../../services/subscription";

type SelectionPlanProps = {
  handleSelectPlan: (plan: SubscriptionPlan) => void;
  selectedProduct?: string;
};

export const SelectionPlan = ({
  handleSelectPlan,
  selectedProduct,
}: SelectionPlanProps) => {
  const [plans, setPlans] = useState([]);

  const fetchPlans = async () => {
    const res = await getAllPlans();

    if (selectedProduct) {
      const parsed = res.data.filter(
        (product: any) => product.product === selectedProduct
      );
      setPlans(parsed);
    } else {
      setPlans(res.data);
    }
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  const handleSelectedPlan = (plan: SubscriptionPlan) => {
    handleSelectPlan(plan);
  };

  return (
    <div className="selection-plan-container">
      {plans.length > 0 &&
        plans.map((plan: SubscriptionPlan) => {
          if (plan.active && plan.countries.includes("fr")) {
            return (
              <PlanItem
                plan={plan}
                handleOnClick={() => handleSelectedPlan(plan)}
                isSubscription={!plan.oneTimePayment}
              />
            );
          }
        })}
    </div>
  );
};
