import axios from "axios";
import { axiosAuthenticated } from "./Auth";

export const getPublicGames = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API_NG_URL}/public/games`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching games:", error);
  }
};

export const getPublicPlatforms = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API_NG_URL}/public/platforms`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching platforms:", error);
  }
};

export const getUserGames = async (userId: string) => {
  try {
    const response = await axiosAuthenticated.get(
      `${process.env.REACT_APP_BASE_API_NG_URL}/users/${userId}/games`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user games:", error);
  }
};

export const putUserGames = async (userId: string, games: string[]) => {
  try {
    const response = await axiosAuthenticated.put(
      `${process.env.REACT_APP_BASE_API_NG_URL}/users/${userId}/games`,
      { games }
    );
    return response.data;
  } catch (error) {
    console.error("Error put user games:", error);
  }
};

export const putUserCurrency = async (userId: string, newCurrency: string) => {
  try {
    const response = await axiosAuthenticated.patch(
      `${process.env.REACT_APP_BASE_API_NU_URL}/users/${userId}/currency`,
      { newCurrency }
    );
    return response.data;
  } catch (error) {
    console.error("Error put user currency:", error);
  }
};

export const putUserLanguage = async (userId: string, newLanguage: string) => {
  try {
    const response = await axiosAuthenticated.put(
      `${process.env.REACT_APP_BASE_API_NG_URL}/users/${userId}/newLanguage`,
      { newLanguage }
    );
    return response.data;
  } catch (error) {
    console.error("Error put user language:", error);
  }
};

export const putUserPlatforms = async (userId: string, platforms: string[]) => {
  try {
    const response = await axiosAuthenticated.put(
      `${process.env.REACT_APP_BASE_API_NG_URL}/users/${userId}/platforms`,
      { platforms }
    );
    return response.data;
  } catch (error) {
    console.error("Error put user platforms:", error);
  }
};

export const getPublicGameAccounts = async () => {
  try {
    const response = await axiosAuthenticated.get(
      `${process.env.REACT_APP_BASE_API_NG_URL}/public/accounts`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching public games accounts:", error);
  }
};

export const getUserGameAccounts = async () => {
  try {
    const response = await axiosAuthenticated.get(
      `${process.env.REACT_APP_BASE_API_NG_URL}/users`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user games:", error);
  }
};

export const putUserGameAccount = async (game: string, gamerTag: string) => {
  try {
    const response = await axiosAuthenticated.put(
      `${process.env.REACT_APP_BASE_API_NG_URL}/accounts/${game}/users`,
      { gamerTag }
    );
    return response.data;
  } catch (error) {
    console.error("Error put user games:", error);
  }
};
