import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { UserModel } from "../../models/User";
import { getUserInfos, populateStorage } from "../../services/Auth";
import { getUserById } from "../../services/no-user";
import { AccountSettingsContent } from "./Sections/AccountSettings";
import { CurrencyContent } from "./Sections/Currency";
import { MyGamesContent } from "./Sections/MyGames";
import { PasswordContent } from "./Sections/Password";
import { PremiumHistorySettings } from "./Sections/PremiumHistory";
import { ProfilePictureContent } from "./Sections/ProfilePicture";
import "./UserSettings.scss";

export const UserSettingsPage = () => {
  const [user, setUser] = useState<UserModel>();
  const [storedUser, setStoredUser] = useState(
    JSON.parse(localStorage.getItem("user") ?? "")
  );
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("settingsTab") ?? "accountSettings"
  );

  useEffect(() => {
    if (storedUser) {
      handleUpdateUser();
    }
  }, [storedUser]);

  const changeTab = (tabName: string) => () => {
    setActiveTab(tabName);
    localStorage.setItem("settingsTab", tabName);
  };

  const handleGetUser = () => {
    getUserInfos().then((res) => {
      setStoredUser(populateStorage(res));
    });
  };

  const handleUpdateUser = async () => {
    getUserById(storedUser.identity.id).then((response) => {
      setUser(response);
    });
  };

  const renderContent = () => {
    if (user) {
      switch (activeTab) {
        case "accountSettings":
          return (
            <AccountSettingsContent
              user={storedUser}
              handleGetUser={handleGetUser}
            />
          );
        case "myGames":
          return <MyGamesContent user={storedUser} />;
        case "password":
          return <PasswordContent />;
        case "currency":
          return <CurrencyContent user={storedUser} />;
        case "images":
          return (
            <ProfilePictureContent user={user} updateUser={handleUpdateUser} />
          );
        case "premium":
          return <PremiumHistorySettings />;
      }
    }
  };

  return (
    <div className="user-settings-page-container">
      <div className="left-content">
        <div className="left-menu-container">
          <div
            className={`account-settings-menu-item ${
              activeTab === "accountSettings" ? "active" : ""
            }`}
            onClick={changeTab("accountSettings")}
          >
            <h6>
              <FormattedMessage id={"webapp.settings.account-settings"} />
            </h6>
          </div>
          <div
            className={`account-settings-menu-item ${
              activeTab === "myGames" ? "active" : ""
            }`}
            onClick={changeTab("myGames")}
          >
            <h6>
              <FormattedMessage id={"webapp.settings.my-games"} />
            </h6>
          </div>
          <div
            className={`account-settings-menu-item ${
              activeTab === "password" ? "active" : ""
            }`}
            onClick={changeTab("password")}
          >
            <h6>
              <FormattedMessage id={"webapp.settings.password"} />
            </h6>
          </div>
          <div
            className={`account-settings-menu-item ${
              activeTab === "currency" ? "active" : ""
            }`}
            onClick={changeTab("currency")}
          >
            <h6>
              <FormattedMessage id={"webapp.settings.currency"} />
            </h6>
          </div>
          <div
            className={`account-settings-menu-item ${
              activeTab === "images" ? "active" : ""
            }`}
            onClick={changeTab("images")}
          >
            <h6>
              <FormattedMessage id={"webapp.settings.medias"} />
            </h6>
          </div>
          <div
            className={`account-settings-menu-item ${
              activeTab === "premium" ? "active" : ""
            }`}
            onClick={changeTab("premium")}
          >
            <h6>
              <FormattedMessage id={"menu.label.premium"} />
            </h6>
          </div>
        </div>
      </div>
      <div className="right-content">{renderContent()}</div>
    </div>
  );
};
