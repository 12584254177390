import { Button, NCInput } from "@wolfiesports/srm-component";
import { useEffect, useState } from "react";
import {
  PaymentIntentType,
  SubscriptionPlan,
} from "../../../models/SubscriptionModel";
import { postMpesaIntent } from "../../../services/subscripiton-mpesa";

// const mpesaPhoneRegex = /^(?:254|\+254|0)[0-9]{6,9}$/;

type PremiumModalMpesaMethodProps = {
  selectedPlan: SubscriptionPlan;
};
export const PremiumModalMpesaMethod = ({
  selectedPlan,
}: PremiumModalMpesaMethodProps) => {
  const [phoneNumber, setPhoneNumber] = useState("");

  const handlePhoneNumberChange = (newPhoneNumber: string) => {
    setPhoneNumber(newPhoneNumber);
  };

  useEffect(() => {
    console.log(selectedPlan);
    postMpesaIntent(PaymentIntentType.PREMIUM, {
      phoneNumber: "0809090909",
      premiumPlanId: selectedPlan._id,
    });
  });

  return (
    <>
      <NCInput
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        type="phone"
        label={"Téléphone"}
      />
      <Button label={"Send"} />
    </>
  );
};
