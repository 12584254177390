import "./Legals.scss";

export const PrivacyPage = () => {
  window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  return (
    <div className="terms-of-use">
      <span></span> <h1>PRIVACY POLICY AND COOKIES</h1> <br />
      <br /> <br />
      The website accessible to the following address
      <a href="https://wolfiesports.com ">https://wolfiesports.com </a> is
      published by the company WOLFIE CLASSICS LTD (hereinafter the “ Platform ”).
      <br />
      <br />
      The company WOLFIE CLASSICS LIMITED attaches great importance to the
      protection of privacy and data relating players on its Platform, customers and
      prospects of the Platform, to its employees, partners and service providers.
      WOLFIE CLASSICS LIMITED is therefore careful to adopt and respect the
      principles applicable to the protection of personal data for all its
      activities.
      <br />
      <br />
      The purpose of this privacy and cookies policy (hereinafter the “Policy”)
      is to inform any user of this website (hereinafter “You”/“Your”) in a
      manner in which WOLFIE CLASSICS LIMITED(hereinafter “We”/“Our”) in its
      capacity of controller, collects and use data concerning You and about the
      means You have at Your disposal to control this use and exercise Your
      rights relating thereto.
      <br /> <br />
      In addition, a specific mention appears on each form of collect to ensure
      that You are fully informed prior to any processing of Your data. Each
      user is also invited to read Our section concerning cookies, available in
      Paragraph 11 of this document. <br /> <br /> <br />
      By navigating in Our Platform, You accept the terms and conditions of this
      Privacy policy and consent to the collection and use of Your personal data
      as defined herein. We may change this Privacy Policy at any time. If We
      decide to change it, We will post those changes on this page so that You
      are always aware of what information We collect, how We use it and under
      what circumstances We disclose it. We encourage You to periodically review
      this Privacy Policy to be aware of any changes since You last visit.
      <br /> <br />
      The continued access or use of Our Platform constitute Your express
      acceptation of every modification apported at the present Privacy Policy.
      If You don’t agree with any provision of this Privacy Policy, please don’t
      use Our services.
      <br /> <br />
      <b>1.Responsible of treatment</b> <br /> <br />
      WOLFIE CLASSICS LIMITED, WOLFIE CLASSICS LTD a limited liability company
      registered under Registration number PVT-Y2UJ7B2 and having its registered
      address at Bruce House, Standard street, Nairobi , Kenya
      <br /> <br /> <b>2. Purpose of the treatment</b> <br /> <br />
      We only treat Your data to guarantee You can optimally use Our services, to
      answer Your requests.
      <br />
      If You consent to this when You register, we will use Your email to inform
      You of Our news and promotions.
      <br />
      In the context of recruitment, we will keep Your data (ex : CV) during one
      year.
      <br />
      WOLFIE CLASSICS LIMITED need to collect and process certain data
      concerning You, as far as they are, as the case may be, necessary for the
      performance of a sales or service contract, in the context of a
      recruitment campaign, to comply with legal or regulatory obligations,
      collected with Your prior consent or in the legitimate interest of WOLFIE
      CLASSICS LIMITED(ex : for the security of Your transactions).
      <br /> <br /> <b> 3. How do We collect Your data ? </b> <br /> <br />
      We may collect information about You on a number of different occasions,
      in particular :
      <br />
      When You create a user account on the Platform or via Wolfie Sports mobile
      application and in the context of using its functionalities
      <br />
      When You purchase a product or service offered on the wolfiesports.com
      website
      <br />
      When You use one of Our contact forms or when You interact with Us by
      another means (email, telephone, social networks, etc)
      <br />
      When You respond to one of Our satisfaction survey
      <br />
      When You subscribe to Our marketing communications
      <br />
      When You navigation on Our Platform
      <br />
      When You are a candidate for an offer to join Our Company.
      <br /> <br />
      Your data may also have been transmitted to Us through sponsorships or
      third party partners.
      <br /> <br /> <b> 4. Why do We collect Your data ? </b> <br /> <br />
      WOLFIE CLASSICS LIMITED collects only adequate data, relevant and limited
      data that is necessary for the purposes for which it’s processed.
      <br /> <br />
      These purposes are specific and legitimate and, under no circumstance,
      Your data will not be further treated in a manner incompatible with these
      purposes, unless We have Your prior consent.
      <br /> <br />
      When You register on Our Platform, We may use Your data, as the case may be,
      for :
      <br />
      Confirm Your account
      <br />
      Send You an email in the case You forget Your password and when it is
      reset.
      <br /> <br />
      In addition, We may use Your data in the case of tournaments that We
      organize on Our Platform. We can You send an email for :
      <br />
      Notify You that the inscription has been taken into account :
      <br />
      Confirming Your participation in the tournament
      <br />
      Notify You of Your victory in the tournament.
      <br /> <br />
      Also, and due to the provision by WOLFIE CLASSICS LIMITED of the requested
      service (in accordance with Article 6 §1 alinéa a of the RGPD), We may
      also use Your data for :
      <br />
      Respond to Your purchase or contact requests
      <br />
      Manage the functionalities of the services that We offer
      <br />
      Manage and execute orders
      <br />
      Manage order cancellations
      <br />
      Manage after-sales service
      <br />
      Manage possible complaints
      <br />
      As well as to optimize Our Platform and Our offers or products and services.
      <br /> <br />
      Furthermore, We are susceptible to collect Your data in the context of the
      Wolfie Sports Mobile Application for :
      <br />
      Statistics reasons
      <br />
      To register/confirm the registration <br />
      Registering for a tournament
      <br />
      Prove Your results in a tournament via the possibility to take a photo
      <br />
      Contact the arbiter or send a message to customer support
      <br />
      Create and manage Your team
      <br />
      Receive notifications of current and upcoming matches.
      <br /> <br />
      Of course, We are susceptible to collect Your data when You apply for an
      offer in Our Company.
      <br /> <br />
      Finally, Your data will be used for direct marketing purposes by email or
      SMS subject to Your prior consent. You have the possibility to unsubscribe
      to Our offers of products and services at any time. As an exception, and
      in accordance with the applicable regulations, in the event that this data
      has been collected in the context of the sale of a product or service
      provided by WOLFIE CLASSICS LIMITED, Your contact information may be used
      without Your consent. In these two hypotheses, WOLFIE CLASSICS LIMITED
      treat Your data for the purposes of interests considered legitimate by the
      regulations, without this having any consequences on Your rights. You may
      object to this at any time, as indicated in the § 3 of the present
      Document.
      <br /> <br />
      We are also susceptible to organize surveys in which You are entirely free
      to participate or not.
      <br /> <br />
      The information on the way You use Our products and services will only be
      used to personalize You user experience, Our communications and possible
      offers to Your attention.
      <br /> <br />
      The sole purpose of establishing such a profile is to enable Us to better
      respond to Your preferences and needs.
      <br /> <br /> <b> 5. What data do We collect ? </b> <br /> <br />
      The information We collect falls into three categories : (i) information
      that You provide to Us, (ii) information collected automatically, and
      (iii) information that We obtain from third parties.
      <br /> <br />
      5.1. The information You provide Us with
      <br /> <br />
      <i> 5.1.1. For players and consumers (professional and amateurs)</i>
      Your civility, name and surname, to know how to call You and recognize
      You. We also ask You if You are a minor. If You are a minor, the identity
      of Your legal representative
      <br />
      For participation in tournaments, and in the event that You are a minor,
      We will ask You for Your national identity card to verify that You are
      over 12 years of age, in accordance with the rules in force, as well as
      that of Your legal representative. As part of Your participation in
      tournaments, We will also ask You for Your “Gamer” identity (i.e.: Steam
      ID, PSN, Xbox account). This allows You to recognize and identify Yourself
      for participation in the tournaments
      <br />
      Your email, to access Your user account, to contact You at Your request,
      or, subject to Your prior agreement when required by applicable
      regulations, to exchange with You on Our news and promotions. If You are a
      minors, the email address of Your legal representative to ensure that
      he/she has read Your registration to Our Platform and that he/she authorizes
      You to participate in Our tournament
      <br />
      Your country of origin when You win tournaments, for statistical purposes,
      if You authorize Us to do so
      <br />
      Your postal address for delivery and billing purposes, or to exchanges
      with You on Our news and promotions
      <br />
      Your size, as soon as You win a clothing reward or when You acquire a
      cloth on Our Shop online
      <br />
      The games You play and the time You spend playing them in order to
      personalize Our offers or products and services
      <br />
      Your telephone number, for the follow-up of Your order or reservation, or
      to contact You at Your request
      <br />
      You bank information if You win a predefined monetary reward during a
      tournament and You wish that WOLFIE CLASSICS LIMITED pay You the amount by
      bank transfer
      <br />
      In this context, We will also ask You national identity card to verify
      that the bank information are adequate with Your identity in order to
      detect fraud and protect You against it
      <br />
      Your PayPal ID if You prefer to be paid via this payment method when You
      win a prize in a tournament.
      <br />
      Optionally, You have the possibility to complete Your profile public with
      the following personal data :
      <br />
      Biography
      <br />
      Age
      <br />
      Streams
      <br />
      Facebook, Twitter, Twitch, YouTube and Steam accounts
      <br /> <br /> <i>5.1.2. For recruitment candidates </i> <br /> <br />
      Data relating to Your identity and identification data : birth name,
      spouse’s name, first name, title, photo (optional), personal details
      (address, personal telephone number, email)
      <br />
      Data relating to Your working life : dates of beginning and end of
      contracts with Your previous employers, diplomas, and in general any
      information related to You curriculum vitae, the nature of Your work and
      Your missions.
      <br /> <br /> <i>5.1.3. For professionals </i> <br /> <br />
      Your name and surname, to known how to call can You recognize You
      <br />
      Your activity and the corporate name of Your company (if applicable) in
      order to better understand the nature of Your request
      <br />
      Your Kbis or Your identity card, if necessary, to verify Your identity
      <br />
      Your email, to access at Your user account, to contact You at Your
      request, or to exchange with You on news and other information related to
      the brand Wolfie Sports
      <br />
      Your history of contact with Us to provide to provide You with assistance.
      <br />
      Required information are those indicated as such by the symbol (*). If You
      don’t fill this information, We will not be able to access at Your
      request. The other data are optional, i.e. You are free to complete, or
      not, this data without any obligation or particular inconvenience for You.
      <br /> <br /> <i>5.2. Data collected automatically </i> <br /> <br />
      We and Our third-party service providers may automatically collect certain
      information as a result of Your use or Your navigation on Our Platform. This
      information generally includes :
      <br /> <i>5.2.1. Usage Data </i> <br />
      When You use Our services, we and Our third-party service providers may
      track and collect usage data and information about Your interactions with
      Our Platform, such as :
      <br /> <br />
      Your connection logs to alert You in the event of a suspicious connection.
      Retrieving this data will also allow You to automatically reconnect to Our
      Platform during Your next visit
      <br />
      The pages that You visit and the links You click on
      <br />
      The Games You play, the duration of Your games, Your game history
      <br />
      Chat history, contact and order history, the date and time You access Our
      servers
      <br /> <br />
      The usage data is collected for the legitimate purpose of data processing
      in the context of Our business, to know,
      to assist with access or other question on the Platform, to provide You with
      services more adapted to Your requests, to enable order returns and
      processing, and to improve Our products and services.
      <br /> <br /> <i>5.2.2. Cookies and IP address </i> <br /> <br />
      Like most companies, when You visit Our Platform, We and Our service providers
      may use cookies and other tracking technologies to improve the quality of
      Our services :
      <br />
      Adresse IP : an internet protocol (IP) address is a number that is
      automatically assigned to Your device from which You access Our Platform by
      Your internet service provider, and is automatically identifies and
      registered in Our files whenever You navigate on Our Platform
      <br />
      When You navigate on Our Platform, You make several requests to access the
      pages that You wish to consult. Our servers, hosted by Amazon web service,
      then receive Your requests and this information allows Us to know which
      page You have visited, the time of Your visit, and Your IP address. We and
      Our third-party service providers use this information to measure Platform
      audience, diagnose server problems, administer the Platform, and approximate
      Your location. This information is necessary so that You can benefit from
      the server the most approximate of Your location when You access Our Platform
      and thus reduce latency
      <br />
      “Cookies” are small elements of information that are placed on Your device
      when You visit a website. We may use session cookies (which expire when
      You close Your web browser) and persistent cookies (which remain on Your
      computer until they are deleted) to provide You with a more personal and
      interactive experience with the Platform. When You visit a website, the computer
      asks Your computer for permission to register this file in a portion of
      Your hard drive specifically for cookies. Each website may send its own
      cookie to Your browser if Your browser’s preferences allow it. We don’t
      register personal information in cookies on Your computer. Persistent
      cookies can be deleted by following Your browser’s instructions in the
      “Help” tab. However, in order to use certain services on Our platform,
      Your web browser must accept the cookie, and therefore certain aspects of
      Our services may not function properly if You choose to disable cookies.
      For more information, We invite You to read §1 of this Policy privacy,
      regarding cookies
      <br />
      We may also use invisible pixels, to collect the features and information
      that You have searched for and accessed, and also to know when You have
      opened an email sent by Our services. This type of information is
      collected in order to make the content of Our Platform more adapted to Your
      needs.
      <br /> <br /> <br /> <b>6. With who We share Your data ? </b> <br />
      <br />
      The database created when you register for Our services is strictly
      confidential. We undertake to take all useful precautions and appropriate
      organizational and technical measures to preserve the security, integrity
      and confidentiality of the data and, in particular, to prevent it from
      being distorted, damaged or accessed by unauthorized third-party.
      <br /> <br />
      Our database provider is MongoDB Atlas, a service fully managed by Amazon
      Web Service (AWS). You can view their privacy policy at:
      https://www.mongodb.com/legal/privacy-policy.
      <br /> <br />
      For the purposes of the realization of purposes of treatment that We carry
      out, as stated above, only Our technical service has access to all of Your
      data. Our tournaments administrators have only access at Your identity
      card when You win a tournament or You are minor, and Your email. This
      later data is also shared with Our Marketing Department in the legitimate
      interest of Our company. For the legitimate purpose of Our services and to
      accommodate jet lag, some of Our tournament administrators operate in 
      countries different from Our's.
      They therefore have access to Your email address but still comply with the
      the General Data Protection Regulations.
      <br /> <br />
      Concerning Your bank detail and for the legitimate purpose of the
      treatment previously mentioned, this data is transmitted to the “Stripe”
      platform that We use to manage all bank payments. You can find the privacy
      policy of Stripe at:
      <a href="https://stripe.com/ie/privacy">https://stripe.com/ie/privacy </a>
      . All mobile money payments are handled by the M-Pesa platform. The Mpesa
      privacy policy can be found at
      <a href="https://www.safaricom.co.ke/dataprivacystatement/">
        https://www.safaricom.co.ke/dataprivacystatement/
      </a>
      .
      <br /> <br />
      Our service providers may also be recipients of personal data strictly
      necessary to realize the services that We have entrusted to them and in
      particular those that We use to carry out orders (ex : delivery data,
      etc).
      <br /> <br />
      The public profile that You establish online on the Platform can be view by
      Your friends, the others players or the general public if You participate
      in tournaments or use certain features of Our Platform (ex : Chat
      applications).
      <br /> <br />
      Your data may be shared with Our subsidiaries so that they can assist Us
      in the performance of Our services or process data on Our name. We are
      also likely to communicate Your data to respond to legal or regulatory
      requests, court decisions, subpoenas or legal proceedings, if compliance
      with the regulations in force so requires, as well as in the event where
      WOLFIE CLASSICS LIMITED transfers all or part of its assets to a
      third-party company.
      <br /> <br />
      Subject to Your prior and express consent, Your email and telephone number
      may also be transmitted to Our partners for commercial prospecting
      purposes by email and/or SMS, and benefit from their own newsletters,
      discounts, offers and other promotions.
      <br /> <br /> <b>7. How long is Your data kept ? </b> <br />
      <i>7.1. Regarding the players data </i> <br /> <br />
      Your user data will be kept by WOLFIE CLASSICS LIMITED until You decide to
      delete Your account or after a period of inactivity of 3 years.
      <br />
      The parental authorization and the national identity card of Your legal
      representative that We ask You to provide if You are minor, is kept for a
      period of one year following its receipt, in accordance with the
      regulations in force.
      <br />
      Your banking data collected at the time of the payment of a cash prize is
      kept for a period no exceeding 13 months, in accordance with the rules in
      force.
      <i>7.2. Regarding the connection data</i> <br /> <br />
      Your connection data to the Platform will be kept only for the time strictly
      necessary for the transaction. Nevertheless, in the context of a monthly
      subscription, the Stripe platform will keep Your bank details until You
      decide to stop Your subscription, in order to facilitate the continuity of
      the Premium Service.
      <br /> <br /> <b>8. Security </b> <br /> <br />
      The protection of Your privacy and Your data is one of Our priorities. Any
      registered user is invited to create a password. We invite You to keep
      this password secret, limit access to Your computer or mobile device and
      log out after using Our services.
      <br />
      Furthermore, within WOLFIE CLASSICS LIMITED, We take adequate security
      measures, in particular technical and organizational measures, to protect
      Your data against accidental loss, destruction, abuse, damage and
      unauthorized or illegal access.
      <br />
      As all personal data is confidential, access to it is thus limited to
      WOLFIE CLASSICS LIMITED employees, service providers acting on behalf of
      WOLFIE CLASSICS LIMITED , who need it to carry out their missions.
      <br />
      All persons having access to Your data are bound by a duty of
      confidentiality and are subject to disciplinary measures and/or other
      sanctions if they fail to comply with these obligations.
      <br />
      Transactions with a third-party recipient are subject to a contract in
      order to assure You of the protection of Your personal data and the
      respect of Your rights.
      <br />
      Unfortunately, the transmission of information via the internet is never
      completely secure. Although We do Our best to protect Your personal data,
      We cannot guarantee that unauthorized third parties will not gain access
      to Your personal data. Therefore, any transmission of personal information
      is at Your own risk.
      <br /> <br />
      <b>9. What are Your rights and how do You exercise them ?</b> <br />
      <br />
      You have, subject to the limits provided by the regulations in force,
      several rights with regard to the treatment of Your data :
      <br />
      To right to be informed about the use of Your personal information
      <br />
      The right to access the personal information that We have about You
      <br />
      The right to request the correction of erroneous personal information that
      We have about You (which You can also do directly in the section “My
      Account” when it exists)
      <br />
      The right to request that We delete Your data, or that We stop processing
      or collecting it, in certain circumstances
      <br />
      The right to withdraw Your consent to receive news from Us and to object
      to the processing of Your data (in particular with a view to sending You
      direct Marketing messages)
      <br />
      The right to delete Your data and to object to the treatment of Your data
      if You consider that WOLFIE CLASSICS LIMITED doesn’t have appropriate
      rights to do so or if You wish to ask Us not to process Your personal data
      for direct marketing purposes
      <br />
      The right to request that We hand over Your data to You or transfer them
      to another service provider (“right of portability”)
      <br />
      The right to formulate specific and general post-mortem guidelines
      concerning the conservation, deletion and communication of Your data
      <br />
      The right to complain to the national authority in charge of personal data
      protection (in France it’s the Cnil “Commission Nationale de l’Information
      et des Libertés” accessible at :
      <a href="https://www.cnil.fr/">https://www.cnil.fr/ </a>) and in Kenya, it
      is the office of the Data Protection Commissioner accessible at
      <a href="https://www.odpc.go.ke/">https://www.odpc.go.ke/ </a>.
      <br />
      For any question and/or to exercise Your rights You can contact us via the
      forms available on our website https://www.wolfiesports.com
      <br /> <br />
      If you are not connected to your user account you can access the contact
      form at the bottom of the wolfiesports.com home page entitled "Contact Us".
      You must fill in the following fields: name, email, select an option from
      the drop-down menu (sponsorship, support, etc.) and complete your request
      by explaining it in the box provided. Then, you must click on "send".
      <br /> <br />
      If you are logged in to your Wolfie Sports user account, you can access the
      contact form in the menu on the left side of your home screen "Contact
      Us". You must complete the following fields: name (optional), email,
      explain your request in the box provided. You have the possibility to
      attach up to 5 files. Finally, you must click on "send".
      <br /> <br />
      To stop receiving marketing messages from WOLFIE CLASSICS LIMITED, You may
      also click on the unsubscribe link in each of Our messages.
      <br /> <br />
      WOLFIE CLASSICS LIMITED undertakes to reply to You as soon as possible, and
      in any event, within one month of receipt of Your request.
      <br /> <br />
      If necessary, this period may be extended by 2 months, considering the
      complexity and number of requests sent to Us. In this case, You will be
      informed of this extension and the reason of that.
      <br /> <br />
      If Your request is submitted in electronic form, the information will also
      be provided to You electronically whenever possible, unless You expressly
      request otherwise.
      <br /> <br />
      If We don’t comply with Your request, it will inform You of the reasons
      for its inaction and You have the possibility to make a complaint with a
      supervisory authority and/or taking legal action.
      <br /> <br /> <b>10. Social networks </b> <br /> <br />
      You can join Our social networks (Instagram, Twitter, Facebook accounts)
      from Our Platform and, thanks to them, follow Our news. The only data that We
      may collect are anonymous and treated for purely statistical purposes (ex
      : follow the number of subscribers to Our account, etc).
      <br /> <br />
      In addition, this Privacy policy doesn’t address, and we aren’t
      responsible for, the privacy, information or other data collection,
      storage and use practices of any third-party, including but not limited
      to, any linked Platform, Facebook Inc., Apple Inc., Google Inc., Microsoft
      Corp, Research in Motion, Valve Corp, Amazon Inc., any mobile service
      provider and any third-party operating any website that You may access via a
      URL on Our Platform.
      <br /> <br />
      We don’t monitor or control the information collection or privacy
      practices of third-parties, and We accept no responsibility for their
      privacy policies.
      <br /> <br />
      We encourage You to familiarize Yourself with the privacy practices of
      each website that You visit and to contact them if You have any questions
      about their respective privacy policies and practices.
      <br /> <br />
      For more information on this subject, as well as to know Your rights in
      this regard and to set up options to protect Your privacy, We invite You
      to consult the privacy policy of the website of the social network
      concerned :
      <br />
      Instagram’s privacy policy : https://help.instagram.com/155833707900388
      <br />
      Facebook privacy policy : https://www.facebook.com/privacy/policy/
      <br />
      YouTube privacy policy : https://policies.google.com/privacy
      <br />
      Twitter privacy policy : https://twitter.com/en/privacy
      <br /> <br /> <b>11. Cookies </b> <br /> <br />
      WOLFIE CLASSICS LIMITED may use tracking technologies, such as cookies,
      markers, scripts, used in applications.
      <br /> <br />
      A cookie is a small file composed of letters and numbers that is placed on
      Your device when You visit a website and contains information that is
      transferred on Your computer. This information is sent back to the website
      on each subsequent visit or to another website that recognizes this
      cookie.
      <br /> <br />
      We use this technology for various purposes, including maintaining user
      settings (language, choice of games…), as well as analyzing customer
      itinerary (page viewed on Our Platform and website pages that You visit or
      products You search on Our Shop online, or the search terms that refer You
      to Our Platform). On Your computer, the cookies are managed by Your internet
      browser. These cookies may be session cookies (in this case the cookie
      will be automatically deleted when the browser is closed) or persistent
      (in this case the cookie will remain stored in Your terminal until its
      expiry date).
      <br /> <br />
      You will find below the cookies used on Our Platform https://www.wolfiesports.com
      <a href="https://www.wolfiesports.gg">https://www.wolfiesports.com </a>. :
      <br />
      <table>
        <tr>
          <th>Technical name of cookies:</th> <th>Objectives:</th>
        </tr>
        <tr>
          <td> _gtag</td>
          <td>
            GOOGLE ANALYTICS: allows to record the statistics on the material of
            the internet user and on his course (statistics) in the Platform. No
            nominative data is stored.
          </td>
        </tr>
        <tr>
          <td>_gat</td> <td>Cookies used by GOOGLE services</td>
        </tr>
        <tr>
          <td>_ga</td> <td></td>
        </tr>
        <tr>
          <td>_gid</td> <td></td>
        </tr>
        <tr>
          <td>SESSION</td>
          <td>Allows You to save Your session identifiers</td>
        </tr>
        <tr>
          <td>_stripe_mid</td> <td>Cookies used by Stripe Services</td>
        </tr>
        <tr>
          <td>_stripe_sid</td> <td></td>
        </tr>
      </table>
      <br /> <br /> <b>HOW TO ACCEPT OR REFUSE COOKIES ? </b> <br /> <br />
      You can configure Your browser so that cookies are saved in Your terminal
      or rejected, either systematically or according to their issuer, or to be
      informed at the time a cookie is saved in Your terminal, to allow You to
      accept or reject it.
      <br /> <br />
      However, the deletion of all cookies used by the browser, including those
      used by other websites, may lead to the alteration or loss of certain
      settings or information and make it difficult to navigate the website.
      <br /> <br />
      The configuration of each browser is different. It’s up to You to follow
      the instructions of the editor of Your browser as follows (links available
      at the date of update of this page):
      <br />
      If You use Internet Explorer :
      <a href="https://support.microsoft.com/fr-fr/help/1742/windows-internet-explorer-delete-manage-cookies ">
        https://support.microsoft.com/fr-fr/help/1742/windows-internet-explorer-delete-manage-cookies
      </a>
      <br />
      If You use Safari:
      <a href="https://support.apple.com/kb/ph17191?locale=fr_FR&amp;viewlocale=fr_FR">
        https://support.apple.com/kb/ph17191?locale=fr_FR&amp;viewlocale=fr_FR
      </a>
      <br />
      If You use Chrome :
      <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=fr">
        https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=fr
      </a>
      <br />
      If You use Opera :
      <a href="https://help.opera.com/Windows/10.20/fr/cookies.html">
        https://help.opera.com/Windows/10.20/fr/cookies.html
      </a>
      <br />
      If Your are using different terminals, make sure to set up the
      corresponding browser for each according to Your preferences.
      <br /> <br />
      If You don’t wish modify the configuration of cookies, simply continue to
      visit Our Platform. For more information on cookies, You may consult the Cnil
      website :
      <a href="https://www.cnil.fr/vos-libertes/vos-traces/les-cookies/">
        https://www.cnil.fr/vos-libertes/vos-traces/les-cookies/
      </a>
      .
      <br /> <br /> <b>12. Applicable law and litigation</b> <br /> <br />
      The Platform is controlled and operated from France. Consequently, this
      Privacy policy, as well as the collection and use of Your personal data
      are governed by French Law.
      <br /> <br />
      As WOLFIE CLASSICS LIMITED is established in Kenya, Our company is subject
      to the Data Protection Act of 2019
      <br /> <br />
      In the event of a dispute and in the event that an amicable agreement
      cannot be reached, the competent court will be the one determined
      according to the applicable rules of procedure.
      <br /> <br /> <b>13. Minors</b> <br /> <br />
      The protection of minors is one of Our priorities.
      <br /> <i>13.1. Creating an account on Our Platform</i> <br /> <br />
      Users under 16 years old may only access and use the services of the Platform
      with the authorization of at least one of the legal representatives. The
      latter must therefore have had the opportunity to examine together the
      terms and conditions of use of Platform and the provisions of this Privacy
      policy. The legal representatives of minor are jointly and severally
      liable for all acts and omissions of their minor children when using the
      Site’s services.
      <br /> <br />
      We reserve the right at any time to request proof of the age of any user
      to confirm that access to and use of the Site complies with this Privacy
      policy and to cancel any non-compliant account. If You are a legal
      representative and You believe that We have collected personal data from
      minors without Your consent, You can contact us via the forms available on
      our website
      <a href="https://www.wolfiesports.com">https://www.wolfiesports.com </a>.
      <br /> <br />
      If you are not connected to your user account, you can access the contact
      form at the bottom of the wolfiesports.com home page entitled "Contact Us".
      You must fill in the following fields: name, email, select an option from
      the drop-down menu (sponsorship, support, etc.) and complete your request
      by explaining it in the box provided. Then, you must click on "send".
      <br /> <br />
      If you are logged in to your Wolfie Sports user account, you can access the
      contact form in the menu on the top side of your home screen "Contact
      Us". You must complete the following fields: name (optional), email,
      explain your request in the box provided. You have the possibility to
      attach up to 5 files. Finally, you must click on "send".
      <i>13.2. Participation in tournaments</i> <br /> <br />
      When a minor declares to be under 12 years old, access to the tournaments
      is prohibited in accordance with the “Code de la sécurité intérieure”. For
      the minors over 12 years old, We make sure that the legal representatives
      have consented to their child’s participation in the tournaments. The
      legal representative of the minor over 12 years old must expressly
      formulate his/her consent, by writing an authorization specifying the
      tournaments for which he/she doesn’t wish the minor to participate. The
      said authorization must be accompanied by a copy of the minor’s identity
      card and of the legal representative.
      <br /> <br />
      <b>14. Updating of the conditions for the treatment of Your data</b>
      <br /> <br />
      Please be aware that We may change this Privacy policy. In the case where
      these modifications will be important, We will keep You informed and seek
      Your consent if required.
      <br /> <br /> <b>15. List of Our partners</b> <br /> <br />
      With Your express consent, We are likely to transmit Your data to Our
      partners, listed below, for commercial prospecting purposes :
    </div>
  );
};
