import './UserTeams.scss';

import { NCCardList, TeamProfileCard, TeamProfileCardInformations } from '@wolfiesports/srm-component';
import { FormattedMessage } from 'react-intl';
import React, { useEffect, useState } from 'react';

import { PublicUserModel } from '../../models/User';

import { getUserTeams } from '../../services/no-team';
import { Team } from '../../models/Team';
import { useNavigate } from 'react-router-dom';

interface UserTeamsProps {
    user: PublicUserModel;
    setCallback?: any;
}

export const UserTeams: React.FunctionComponent<UserTeamsProps> =
    ({ user }) => {
        const [teams, setTeams] = useState<Array<Team>>([]);
        const [teamCards, setTeamCards] = useState<Array<TeamProfileCardInformations>>([]);

        const navigate = useNavigate();

        const convertTeamsInfo = (teams: Team[]): TeamProfileCardInformations[] => {
            let tempCards = new Array<TeamProfileCardInformations>;
            teams.forEach((team) => {
                tempCards.push({
                    tag: team.teamInfo.tag,
                    name: team.teamInfo.name,
                    creationDate: new Date(team.teamInfo.date),
                    ownerName: team.teamInfo.owner.name,
                    ownerCode: team.teamInfo.owner.code,
                    nbMembers: team.teamInfo.members,
                    games: []
                })
            });
            return tempCards;

        };

        useEffect(() => {
            if (teams.length === 0) {
                return
            }
            setTeamCards(convertTeamsInfo(teams))

            // eslint-disable-next-line
        }, [teams]);


        useEffect(() => {
            getTeams();

            // eslint-disable-next-line
        }, [user]);


        const getTeams = async () => {
            const result = await getUserTeams(user.userId);
            setTeams(result)
        }

        if (!teams) {
            return;
        }

        return (
            <div className='user-teams'>
                <div className="user-teams-title">
                    <h6 className="tournament-history-title">
                        <FormattedMessage id={"webapp.userprofile.teams"} /> (
                        {teams.length})
                    </h6>
                </div>
                <div className="user-teams-content">
                    <NCCardList
                        cards={
                            teamCards.map(t => {
                                return (
                                    <TeamProfileCard
                                        backgroundImage={`${process.env.REACT_APP_AWS_S3_BUCKET}/media/default/default-team-banner.svg`}
                                        teamPicture={`${process.env.REACT_APP_AWS_S3_BUCKET}/media/default/default-team-avatar.png`}
                                        teamInformations={t}
                                    />
                                );
                            })
                        }
                        cardClicked={(index) => {
                            navigate(`/team/profile/${teams[index].team}`)}}
                        fullScroll
                    />

                </div>
            </div>
        );
    };