import { ReactSRMWrapper } from "@cactus/react-srm-wrapper";
import { useNavigate } from "react-router-dom";
import { useStoreActions, useStoreState } from "../../store";
import { useLogout, useSession } from "../../utils/session/session";

export const WebappMenu: React.FC = () => {
  const originUrl = process.env.REACT_APP_BASE_BUNDLE_URL + "webapp-menu";
  const navigate = useNavigate();

  const logoutCognito = useLogout();
  const { data: user } = useSession({ enabled: true });
  const localUser = useStoreState((s) => s.login.user);
  const setIsLoggedIn = useStoreActions((s) => s.login.setIsLoggedIn);
  const setUser = useStoreActions((s) => s.login.setUser);
  const language = localStorage.getItem("i18nextLng") ?? "en";
  
  const handleLogin = async () => {
    navigate("/login");
  };

  const handleRegister = async () => {
    navigate("/register");
  };

  const handleLogout = async () => {
    if (user) {
      logoutCognito.mutate(user, {
        onSuccess: () => {
          localStorage.removeItem("user");
          localStorage.removeItem("accessToken");
          setUser(null);
          setIsLoggedIn(false);
          window.location.href = "/home";
        },
      });
    }
  };

  const handleNavigate = async (path: string) => {
    console.log(path);
    navigate(path);
  };

  const handleChangeLang = async (language: string) => {
    localStorage.setItem("i18nextLng",language)
    window.location.reload();
  };

  const eventHandlers = {
    login: handleLogin,
    register: handleRegister,
    logout: handleLogout,
    navigateByUrl: handleNavigate,
    changeLanguage: handleChangeLang,
  };

  return (
    <ReactSRMWrapper
      originUrl={originUrl}
      exportPath={"nicecactus.menu"}
      nav={navigate}
      language={language}
      eventHandlers={eventHandlers}
      event={{ type: "user", data: localUser }}
    />
  );
};
